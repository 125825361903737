/* bootstrap overrides */
@media (max-width: 991px) {
  .navbar-header {
    float: none; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  .navbar-text {
    float: none;
    margin: 15px 0; }
  .navbar-collapse.collapse.in {
    display: block !important; }
  .collapsing {
    overflow: hidden !important; } }

/* FONTS */
body {
  font-family: 'Open Sans', sans-serif; }

/* GLOBAL */
.exo {
  font-family: 'Exo', sans-serif; }

.vcenter {
  top: 50%;
  transform: translateY(-50%);
  position: relative; }

.container {
  max-width: 970px; }

.outer-wrap {
  overflow: hidden; }

section {
  padding: 120px 0; }

.divider-line.vertical {
  background-image: linear-gradient(-134deg, #050BB9 0%, #9612AD 100%);
  width: 2px; }

.btn {
  display: inline-block;
  border: 1px solid rgba(8, 11, 35, 0.8);
  border-radius: 2px;
  font-family: "Exo 2";
  font-weight: 600;
  font-size: 18px;
  color: rgba(8, 11, 35, 0.8);
  letter-spacing: 0.38px;
  line-height: 20px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }
  .btn:hover {
    border: 1px solid #3951EA;
    color: #3951EA; }

h1 {
  font-weight: normal;
  margin: 0; }

h2 {
  font-family: "Exo 2";
  font-weight: 500;
  font-size: 36px;
  color: rgba(8, 11, 35, 0.8);
  letter-spacing: 0.75px;
  line-height: 56px; }

h4 {
  font-family: "Exo 2";
  font-weight: 600;
  font-size: 18px;
  color: rgba(8, 11, 35, 0.8);
  letter-spacing: 0.38px;
  line-height: 24px; }

p {
  font-family: "Open Sans";
  font-size: 14px;
  color: rgba(8, 11, 35, 0.8);
  letter-spacing: 0.6px;
  line-height: 24px; }

ul {
  font-family: "Open Sans";
  font-size: 12px;
  color: rgba(8, 11, 35, 0.8);
  letter-spacing: 0.51px;
  line-height: 24px;
  margin-left: 0px;
  padding-left: 15px; }
  ul li {
    margin-left: 10px;
    padding-left: 20px; }

/* NAV */
nav {
  background: #FFFFFF;
  box-shadow: 0 8px 16px 0 rgba(82, 97, 115, 0.18); }
  nav .navbar-header {
    padding-left: 15px; }
  nav.navbar-default {
    border: 0px;
    background-color: #ffffff; }
    nav.navbar-default .navbar-nav a {
      font-family: "Open Sans";
      font-size: 14px;
      color: rgba(8, 11, 35, 0.8);
      letter-spacing: 0.6px;
      line-height: 20px; }
    nav.navbar-default .navbar-nav.scrolling .active {
      border-bottom: 4px solid #ffffff; }
    nav.navbar-default .navbar-nav .active {
      border-bottom: 4px solid #3951EA;
      background-color: #ffffff;
      -webkit-transition: all 0ms ease;
      -moz-transition: all 0ms ease;
      -o-transition: all 0ms ease;
      transition: all 0ms ease; }
      nav.navbar-default .navbar-nav .active a {
        background-color: #ffffff; }
        nav.navbar-default .navbar-nav .active a:hover {
          background-color: #ffffff; }
        nav.navbar-default .navbar-nav .active a:focus {
          background-color: #ffffff; }
    nav.navbar-default .navbar-nav li {
      border-bottom: 4px solid #ffffff;
      padding-left: 0px;
      -webkit-transition: all 0ms ease;
      -moz-transition: all 0ms ease;
      -o-transition: all 0ms ease;
      transition: all 0ms ease; }
      nav.navbar-default .navbar-nav li:hover {
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
        border-bottom: 4px solid #3951EA; }
        nav.navbar-default .navbar-nav li:hover a {
          background: transparent; }
    nav.navbar-default .navbar-toggle {
      border-color: #ffffff;
      position: relative; }
      nav.navbar-default .navbar-toggle.collapsed .icon-bar {
        background-color: #000000;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
        width: 22px; }
      nav.navbar-default .navbar-toggle.collapsed .close {
        z-index: -9;
        opacity: 0;
        transform: rotate(0deg);
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease; }
      nav.navbar-default .navbar-toggle .icon-bar {
        background-color: transparent;
        width: 0px;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease; }
      nav.navbar-default .navbar-toggle:hover, nav.navbar-default .navbar-toggle:focus {
        background-color: #ffffff; }
      nav.navbar-default .navbar-toggle .close {
        position: absolute;
        top: 4px;
        right: 10px;
        opacity: 1;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
        transform: rotate(360deg); }
  nav .navbar-brand {
    background: url(../img/logo.svg) no-repeat left center;
    background-size: 32px;
    display: inline-block;
    font-weight: normal;
    font-family: "Exo 2";
    font-weight: 700;
    font-size: 14px;
    color: #080B23 !important;
    letter-spacing: 1.2px;
    line-height: 20px;
    text-transform: uppercase;
    padding-left: 48px; }

/* HOME SECTION */
#lead {
  background: url(../img/home-hero.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
  padding: 240px 0; }
  #lead h1 {
    font-family: "Exo 2";
    font-weight: 400;
    font-size: 48px;
    color: #FFFFFF;
    letter-spacing: 1px;
    line-height: 56px; }
  #lead .contact-block {
    position: absolute;
    bottom: -40px;
    width: 100%; }
    #lead .contact-block .container {
      background: #FFFFFF;
      border-radius: 2px;
      box-shadow: 0 8px 16px 0 rgba(82, 97, 115, 0.18);
      height: 80px;
      padding: 0 30px; }
    #lead .contact-block h2 {
      font-family: "Open Sans";
      font-size: 24px;
      color: rgba(8, 11, 35, 0.8);
      letter-spacing: 1.25px;
      line-height: 36px; }
    #lead .contact-block .center {
      text-align: center; }
    #lead .contact-block a.btn-contact {
      width: 100%;
      height: 40px;
      margin: 20px auto; }

/* ABOUT */
#about {
  padding: 180px 0; }
  #about .divider-line {
    height: 176px;
    float: right;
    max-height: 0px;
    -webkit-transition: all 700ms ease-in-out;
    -moz-transition: all 700ms ease-in-out;
    -o-transition: all 700ms ease-in-out;
    transition: all 700ms ease-in-out; }
    #about .divider-line.animate {
      max-height: 176px; }
  #about h2 {
    font-weight: normal;
    font-family: "Exo 2";
    font-weight: 600;
    font-size: 18px;
    color: rgba(8, 11, 35, 0.8);
    letter-spacing: 0.38px;
    line-height: 24px;
    margin-top: 15px; }

@keyframes showdivide {
  0% {
    width: 0px; }
  65% {
    width: 74px;
    max-height: 0px; }
  100% {
    max-height: 234px; } }

/* SERVICES */
#services {
  background: #FAFAFA;
  padding-bottom: 180px; }
  #services .service-details-box {
    position: relative;
    overflow: visible;
    min-height: 234px; }
    #services .service-details-box .divider {
      background: url(../img/service-box-left.svg);
      background-repeat: no-repeat;
      background-position: center left;
      height: 234px;
      max-height: 234px;
      top: 50%;
      position: absolute;
      left: -90px;
      transform: translateY(-50%); }
      #services .service-details-box .divider.active {
        width: 74px;
        -webkit-animation: showdivide 1s linear;
        /* Safari 4+ */
        -moz-animation: showdivide 1s linear;
        /* Fx 5+ */
        -o-animation: showdivide 1s linear;
        /* Opera 12+ */
        animation: showdivide 1s linear;
        /* IE 10+, Fx 29+ */ }
      #services .service-details-box .divider.slide {
        width: 0px;
        max-height: 5px;
        -webkit-transition: all 500ms ease-in-out;
        -moz-transition: all 500ms ease-in-out;
        -o-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out; }
    #services .service-details-box .service-inner {
      position: absolute;
      top: 0px;
      opacity: 0;
      -webkit-transition: all 700ms ease-in-out;
      -moz-transition: all 700ms ease-in-out;
      -o-transition: all 700ms ease-in-out;
      transition: all 700ms ease-in-out; }
      #services .service-details-box .service-inner.active {
        opacity: 1; }
  #services .service-interaction {
    margin-top: 60px; }
  #services .service-intro {
    padding-bottom: 60px; }

#wheel-outer {
  height: 314px;
  width: 314px;
  position: relative;
  margin-top: -30px;
  float: right;
  margin-right: 80px; }
  #wheel-outer #wheel-inner {
    height: 314px;
    width: 314px;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%); }
    #wheel-outer #wheel-inner #wheel-ring1 {
      height: 314px;
      width: 314px;
      border-radius: 50%;
      top: 0px;
      left: 0px;
      position: absolute;
      transform: rotate(0deg);
      -webkit-transition: all 700ms ease-in-out;
      -moz-transition: all 700ms ease-in-out;
      -o-transition: all 700ms ease-in-out;
      transition: all 700ms ease-in-out; }
      #wheel-outer #wheel-inner #wheel-ring1 .bg {
        top: 0px;
        left: 0px;
        position: absolute;
        height: 314px;
        width: 314px;
        background: url(../img/logo-wheel.svg) no-repeat center center;
        background-size: 0%;
        opacity: .4;
        transform: rotate(-180deg);
        -webkit-transition: all 500ms ease-out;
        -moz-transition: all 500ms ease-out;
        -o-transition: all 500ms ease-out;
        transition: all 500ms ease-out; }
        #wheel-outer #wheel-inner #wheel-ring1 .bg.visible {
          background-size: 100%;
          opacity: 1;
          transform: rotate(0deg); }
        #wheel-outer #wheel-inner #wheel-ring1 .bg.fade {
          opacity: .4;
          -webkit-transition: all 500ms ease-out;
          -moz-transition: all 500ms ease-out;
          -o-transition: all 500ms ease-out;
          transition: all 500ms ease-out; }

@-webkit-keyframes circles {
  0% {
    width: 22px;
    height: 22px;
    opacity: 0; }
  50% {
    opacity: .8; }
  100% {
    width: 42px;
    height: 42px;
    opacity: 0; } }

@keyframes circles {
  0% {
    width: 22px;
    height: 22px;
    opacity: 0; }
  50% {
    opacity: .8; }
  100% {
    width: 42px;
    height: 42px;
    opacity: 0; } }

@-webkit-keyframes circlehover {
  0% {
    width: 22px;
    height: 22px;
    opacity: .4; }
  100% {
    width: 42px;
    height: 42px;
    opacity: 1; } }

@keyframes circlehover {
  0% {
    width: 22px;
    height: 22px;
    opacity: .4; }
  100% {
    width: 42px;
    height: 42px;
    opacity: 1; } }

.service-circle {
  width: 32px;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
  position: absolute;
  opacity: 0.4; }
  .service-circle.visible {
    opacity: .4;
    border-color: #050BB9; }
  .service-circle:hover {
    border-color: #050BB9; }
  .service-circle.circle-target {
    opacity: 0;
    -webkit-transition: all 700ms ease-out;
    -moz-transition: all 700ms ease-out;
    -o-transition: all 700ms ease-out;
    transition: all 700ms ease-out;
    background-image: linear-gradient(-134deg, #050BB9 0%, #9512AD 99%, #9612AD 100%);
    cursor: pointer;
    cursor: hand; }
    .service-circle.circle-target .radiator {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid #050BB9;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: .5;
      -webkit-animation: circles 2s infinite;
      /* Safari 4+ */
      -moz-animation: circles 2s infinite;
      /* Fx 5+ */
      -o-animation: circles 2s infinite;
      /* Opera 12+ */
      animation: circles 2s infinite;
      /* IE 10+, Fx 29+ */ }
    .service-circle.circle-target.visible {
      opacity: 0.8; }
    .service-circle.circle-target:hover .radiator {
      opacity: 1;
      -webkit-animation: circlehover 1s infinite;
      /* Safari 4+ */
      -moz-animation: circlehover 1s infinite;
      /* Fx 5+ */
      -o-animation: circlehover 1s infinite;
      /* Opera 12+ */
      animation: circlehover 1s infinite;
      /* IE 10+, Fx 29+ */ }
    .service-circle.circle-target:hover {
      opacity: .8;
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease; }
  .service-circle#technology {
    top: -4px;
    left: 141px; }
  .service-circle#advisory {
    top: 141px;
    right: -4px; }
    .service-circle#advisory:hover {
      border-color: #9512AD; }
  .service-circle#business {
    top: 141px;
    left: -4px; }
  .service-circle#capital {
    left: 141px;
    bottom: -4px; }
    .service-circle#capital:hover {
      border-color: #9512AD; }

/* Sectors */
#sectors {
  background: url(../img/sectors-bg.jpg) no-repeat center center;
  background-size: cover;
  color: #ffffff;
  padding: 160px 0 130px; }
  #sectors h2 {
    margin-top: 0;
    color: #ffffff; }
  #sectors p {
    color: #ffffff; }
  #sectors .sector-item {
    width: 33%; }
  #sectors .sector-wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 30px; }
    #sectors .sector-wrap .col-md-4 {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
  #sectors .sector-box {
    background: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.18);
    border-radius: 2px;
    font-family: "Exo 2";
    font-weight: 600;
    font-size: 18px;
    color: rgba(8, 11, 35, 0.8);
    letter-spacing: 0.38px;
    line-height: 24px;
    padding: 16px;
    margin: 60px 0px -30px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 80px;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out; }
    #sectors .sector-box.visible {
      opacity: 1;
      margin: 0px 0px 30px 0; }
    #sectors .sector-box h3 {
      margin: 0px;
      font-family: "Exo 2";
      font-weight: 600;
      font-size: 18px;
      color: rgba(8, 11, 35, 0.8);
      letter-spacing: 0.38px;
      line-height: 24px;
      opacity: 1; }
      #sectors .sector-box h3.visible {
        opacity: 1;
        -webkit-transition: all 1000ms ease;
        -moz-transition: all 1000ms ease;
        -o-transition: all 1000ms ease;
        transition: all 1000ms ease; }
    #sectors .sector-box .icon {
      position: absolute;
      right: 16px;
      top: 50%;
      opacity: 0;
      transform: translateY(-50%); }
      #sectors .sector-box .icon.visible {
        opacity: 1;
        -webkit-transition: all 1000ms ease;
        -moz-transition: all 1000ms ease;
        -o-transition: all 1000ms ease;
        transition: all 1000ms ease; }

/* PROCESS */
@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0; } }

@-webkit-keyframes processradiate {
  0% {
    opacity: .3; }
  25% {
    opacity: .6; }
  100% {
    top: -6px;
    left: -6px;
    opacity: 0;
    width: 36px;
    height: 36px; } }

@keyframes dash {
  to {
    stroke-dashoffset: 0; } }

@keyframes processradiate {
  0% {
    opacity: .3; }
  25% {
    opacity: .6; }
  100% {
    top: -6px;
    left: -6px;
    opacity: 0;
    width: 36px;
    height: 36px; } }

#process {
  padding: 160px 0 180px; }

.process-chart {
  margin: 60px 0 0; }
  .process-chart .process-wave {
    height: 240px;
    margin-bottom: 80px; }
  .process-chart .process-wave {
    position: relative; }
    .process-chart .process-wave .step-arrow {
      -webkit-user-select: none;
      /* Chrome all / Safari all */
      -moz-user-select: none;
      /* Firefox all */
      -ms-user-select: none;
      /* IE 10+ */
      user-select: none;
      /* Likely future */
      background: #FAFAFA;
      border: 1px solid #FAFAFA;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      top: 30px;
      text-align: center;
      cursor: pointer;
      cursor: hand;
      z-index: 999;
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease; }
      .process-chart .process-wave .step-arrow:hover {
        border: 1px solid #3951EA; }
      .process-chart .process-wave .step-arrow.left {
        left: 0px; }
      .process-chart .process-wave .step-arrow.right {
        right: 0px; }
      .process-chart .process-wave .step-arrow img {
        margin: auto; }
    .process-chart .process-wave #drawing {
      position: absolute;
      top: 0px; }
      .process-chart .process-wave #drawing .connector {
        fill: none;
        stroke: #3951EA;
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000; }
        .process-chart .process-wave #drawing .connector.active {
          animation: dash 1s linear forwards; }
        .process-chart .process-wave #drawing .connector.mobile {
          display: none; }
    .process-chart .process-wave .process-node {
      position: absolute;
      text-align: center;
      cursor: pointer;
      cursor: hand; }
      .process-chart .process-wave .process-node .circle-radiator {
        display: block;
        width: 26px;
        height: 26px;
        opacity: 0.6;
        border: 1px solid #3951EA;
        border-radius: 50%;
        position: absolute;
        top: -1px;
        left: -1px;
        opacity: 1;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease; }
      .process-chart .process-wave .process-node.active .circle-radiator {
        -webkit-animation: processradiate 1.5s infinite;
        /* Safari 4+ */
        -moz-animation: processradiate 1.5s infinite;
        /* Fx 5+ */
        -o-animation: processradiate 1.5s infinite;
        /* Opera 12+ */
        animation: processradiate 1.5s infinite;
        /* IE 10+, Fx 29+ */ }
      .process-chart .process-wave .process-node h4 {
        font-family: "Open Sans";
        font-weight: 300;
        font-size: 18px;
        margin: 0; }
        .process-chart .process-wave .process-node h4 span {
          font-family: "Open Sans";
          font-weight: 600;
          font-size: 12px;
          color: rgba(8, 11, 35, 0.2);
          letter-spacing: 0.94px;
          line-height: 32px; }
      .process-chart .process-wave .process-node .indicator {
        background: #3951EA;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        margin: 0 auto;
        z-index: 99;
        position: relative; }
      .process-chart .process-wave .process-node#assess {
        top: -40px;
        left: 140px; }
      .process-chart .process-wave .process-node#act {
        top: -40px;
        left: 550px; }
      .process-chart .process-wave .process-node#devise {
        top: 36px;
        left: 340px; }
      .process-chart .process-wave .process-node#collect {
        top: 36px;
        left: 730px; }
    .process-chart .process-wave .node-inner {
      position: absolute;
      width: 300px;
      max-height: 0px;
      background-repeat: no-repeat;
      background-position: 0px top;
      text-align: center;
      opacity: 0;
      z-index: 1;
      top: 88px;
      left: 376px;
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease; }
      .process-chart .process-wave .node-inner.visible {
        opacity: 1;
        max-height: 300px; }
      .process-chart .process-wave .node-inner .icon {
        margin: 55px 0 0 65px;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: #2F42EA;
        text-align: center; }
        .process-chart .process-wave .node-inner .icon img {
          top: 50%;
          transform: translateY(-50%);
          display: inline-block;
          position: relative; }
      .process-chart .process-wave .node-inner .content {
        margin-left: -50px; }
        .process-chart .process-wave .node-inner .content h3 {
          font-family: "Exo 2";
          font-weight: 600;
          font-size: 18px;
          color: rgba(8, 11, 35, 0.8);
          letter-spacing: 0.38px;
          line-height: 24px;
          max-width: 298px; }
        .process-chart .process-wave .node-inner .content p {
          font-family: "Open Sans";
          font-size: 14px;
          color: rgba(8, 11, 35, 0.8);
          letter-spacing: 0.6px;
          line-height: 24px;
          max-width: 298px; }

/* ADVANTAGE */
@keyframes glowbar {
  0% {
    opacity: .4; }
  50% {
    opacity: 1; }
  100% {
    background-position: 240px 0;
    opacity: .4; } }

@keyframes radiatenode {
  25% {
    opacity: .4; }
  75% {
    opacity: 0; } }

@keyframes radiatenodeoffset {
  50% {
    opacity: .3; }
  100% {
    opacity: 0; } }

@keyframes briteradiatenode {
  25% {
    opacity: .8; }
  75% {
    opacity: 0; } }

@keyframes briteradiatenodeoffset {
  50% {
    opacity: .8; }
  100% {
    opacity: 0; } }

#advantage {
  background: url(../img/advantage-bg.jpg) no-repeat center center;
  background-size: cover;
  color: #ffffff;
  padding: 180px 0; }
  #advantage h2 {
    color: #ffffff;
    margin-top: 0;
    line-height: 42px; }
  #advantage p {
    color: #ffffff; }
  #advantage .chart-wrap {
    position: relative;
    height: 400px;
    width: 100%;
    max-width: 380px;
    text-align: center; }
    #advantage .chart-wrap .chart-inner {
      width: 100%;
      height: 100%; }
    #advantage .chart-wrap .glow-bar,
    #advantage .chart-wrap .soft-bar {
      background-image: url(../img/advantage-glow-bar.svg);
      background-repeat: no-repeat;
      background-position: -240px 0px;
      width: 238px;
      height: 2px;
      opacity: .4;
      position: absolute;
      overflow: visible; }
      #advantage .chart-wrap .glow-bar.animate,
      #advantage .chart-wrap .soft-bar.animate {
        -webkit-animation: glowbar 3.5s;
        /* Safari 4+ */
        -moz-animation: glowbar 3.5s;
        /* Fx 5+ */
        -o-animation: glowbar 3.5s;
        /* Opera 12+ */
        animation: glowbar 3.5s;
        /* IE 10+, Fx 29+ */ }
      #advantage .chart-wrap .glow-bar.a,
      #advantage .chart-wrap .soft-bar.a {
        transform: rotate(55deg);
        top: 200px;
        right: -11px; }
      #advantage .chart-wrap .glow-bar.b,
      #advantage .chart-wrap .soft-bar.b {
        transform: rotate(-55deg);
        top: 200px;
        left: -11px; }
      #advantage .chart-wrap .glow-bar.c,
      #advantage .chart-wrap .soft-bar.c {
        width: 280px;
        top: 300px;
        left: 40px;
        transform: rotate(180deg); }
    #advantage .chart-wrap .soft-bar {
      background: rgba(255, 255, 255, 0.25); }
    #advantage .chart-wrap h3.chart-label {
      font-family: "Open Sans";
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
      letter-spacing: 0.94px;
      max-width: 150px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center; }
    #advantage .chart-wrap .chart-node {
      position: absolute;
      text-align: center;
      width: 230px; }
      #advantage .chart-wrap .chart-node#guidance {
        top: 0px;
        left: 50%;
        transform: translateX(-50%); }
      #advantage .chart-wrap .chart-node#results {
        bottom: 0px;
        left: 35px;
        transform: translateX(-50%); }
      #advantage .chart-wrap .chart-node#adaptable {
        bottom: 0px;
        right: -195px;
        transform: translateX(-50%); }
      #advantage .chart-wrap .chart-node h4 {
        font-family: "Exo";
        font-weight: normal;
        font-size: 18px;
        color: #FFFFFF;
        letter-spacing: 0.38px;
        line-height: 24px;
        width: 120px;
        margin: 0 auto; }
      #advantage .chart-wrap .chart-node .circle {
        background: #FFFFFF;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        text-align: center;
        position: relative;
        margin: 24px auto; }
        #advantage .chart-wrap .chart-node .circle img {
          margin: 12px auto; }
        #advantage .chart-wrap .chart-node .circle .circle-radiator {
          display: block;
          width: 64px;
          height: 64px;
          opacity: 0.6;
          border: 1px solid #FFFFFF;
          border-radius: 50%;
          position: absolute;
          top: -8px;
          left: -8px;
          opacity: 0;
          -webkit-transition: all 500ms ease-in-out;
          -moz-transition: all 500ms ease-in-out;
          -o-transition: all 500ms ease-in-out;
          transition: all 500ms ease-in-out; }
          #advantage .chart-wrap .chart-node .circle .circle-radiator.animate {
            -webkit-animation: radiatenode 3.5s infinite;
            /* Safari 4+ */
            -moz-animation: radiatenode 3.5s infinite;
            /* Fx 5+ */
            -o-animation: radiatenode 3.5s infinite;
            /* Opera 12+ */
            animation: radiatenode 3.5s infinite;
            /* IE 10+, Fx 29+ */ }
          #advantage .chart-wrap .chart-node .circle .circle-radiator.outer {
            opacity: 0.15;
            border: 1px solid #FFFFFF;
            left: -16px;
            top: -16px;
            height: 80px;
            width: 80px;
            opacity: 0; }
            #advantage .chart-wrap .chart-node .circle .circle-radiator.outer.animate {
              -webkit-animation: radiatenodeoffset 3s infinite;
              /* Safari 4+ */
              -moz-animation: radiatenodeoffset 3s infinite;
              /* Fx 5+ */
              -o-animation: radiatenodeoffset 3s infinite;
              /* Opera 12+ */
              animation: radiatenodeoffset 3s infinite;
              /* IE 10+, Fx 29+ */ }

/* CONTACT */
#contact {
  background: #FAFAFA;
  padding-bottom: 160px; }
  #contact .contact-wrap {
    margin-top: 45px; }
  #contact .contact-box {
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(82, 97, 115, 0.2);
    border-radius: 2px;
    margin-right: 30px;
    padding-bottom: 16px; }
    #contact .contact-box h4 {
      box-shadow: inset 0 -1px 0 0 rgba(8, 11, 35, 0.1);
      padding: 16px 16px 12px; }
    #contact .contact-box .contactinfo {
      padding: 0 16px;
      font-family: "Open Sans";
      font-size: 14px;
      color: rgba(8, 11, 35, 0.8);
      letter-spacing: 0.6px;
      line-height: 32px; }
      #contact .contact-box .contactinfo a {
        color: rgba(8, 11, 35, 0.8); }
      #contact .contact-box .contactinfo .icon {
        display: inline-block;
        width: 16px;
        height: 14px;
        margin-right: 14px; }
        #contact .contact-box .contactinfo .icon img {
          height: 14px; }
      #contact .contact-box .contactinfo .addr {
        line-height: 1.14;
        display: inline-block;
        vertical-align: middle; }

/* FOOTER */
footer {
  background-image: linear-gradient(-45deg, #0B0E3F 0%, #200C37 100%);
  color: #ffffff;
  padding: 80px 0 0;
  overflow: hidden; }
  footer p {
    color: #ffffff; }
  footer a {
    color: #ffffff; }
  footer .separator {
    width: 16px;
    height: 4px;
    background: #ffffff;
    margin: 10px 0; }
  footer .ft-logo img {
    width: 68px; }
  footer .copyright {
    text-align: center;
    margin: 80px 0 0px;
    font-family: "Open Sans";
    font-size: 14px;
    color: rgba(255, 255, 255, 0.4);
    letter-spacing: 0.6px;
    line-height: 24px;
    border-top: 2px solid #9212AD;
    padding: 30px 0;
    background-image: linear-gradient(-45deg, #03052B 0%, #200C37 100%); }

/**
 * Tooltip Styles
 */
/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  z-index: 2;
  cursor: pointer; }

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 700ms ease-in-out;
  -moz-transition: all 700ms ease-in-out;
  -o-transition: all 700ms ease-in-out;
  transition: all 700ms ease-in-out; }

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 90%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  margin-bottom: 5px;
  content: attr(data-tooltip);
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(82, 97, 115, 0.2);
  border-radius: 4px;
  font-family: "Open Sans";
  font-size: 11px;
  color: rgba(8, 11, 35, 0.8);
  letter-spacing: 1px;
  line-height: 16px;
  z-index: 99; }

#services [data-tooltip]:before {
  white-space: nowrap; }

#guidance [data-tooltip]:before {
  bottom: 104%; }

#guidance [data-tooltip]:after {
  bottom: 104%; }

#services.hidetips [data-tooltip]:before,
#services.hidetips [data-tooltip]:after {
  display: none; }

#advantage [data-tooltip]:before {
  width: 234px; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 90%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #ffff;
  border-top: 5px solid #ffffff;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0; }

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 700ms ease-in-out;
  -moz-transition: all 700ms ease-in-out;
  -o-transition: all 700ms ease-in-out;
  transition: all 700ms ease-in-out; }

/* responsive stuff */
@media only screen and (max-width: 990px) {
  #lead .contact-block h2 {
    font-size: 18px;
    line-height: 34px; }
  #lead .contact-block a.btn-contact {
    width: 100%; }
  #contact .contact-box {
    margin-bottom: 45px; }
  /*sector*/
  #sectors .sector-item {
    width: 48%; }
  #process .process-chart .process-wave {
    margin-left: -140px; }
    #process .process-chart .process-wave .step-arrow.left {
      left: 110px; } }

@media only screen and (max-width: 860px) {
  #services .col-wheel {
    width: 58%; }
  #services .col-details {
    width: 42%; }
  #services [data-tooltip]:before, #services [data-tooltip]:after {
    opacity: 1 !important;
    visibility: visible !important;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out; }
  #services.hidetips [data-tooltip]:before,
  #services.hidetips [data-tooltip]:after {
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out; } }

@media only screen and (max-width: 767px) {
  section {
    padding: 50px 0; }
  .hide-for-mobile {
    display: none; }
  #lead {
    padding: 200px 0; }
    #lead h1 {
      font-size: 36px;
      line-height: 42px; }
    #lead .contact-block {
      position: absolute;
      bottom: -85px;
      width: 100%; }
      #lead .contact-block .container {
        height: auto;
        padding: 0 30px;
        text-align: center;
        margin: 0 30px; }
      #lead .contact-block h2 {
        font-size: 18px;
        line-height: 24px;
        margin-right: 45px;
        margin-left: 45px; }
      #lead .contact-block a.btn-contact {
        width: 85%; }
  /* ABOUT */
  #about {
    margin-top: 100px; }
    #about .divider-line {
      float: none;
      margin: 10px 15px;
      height: 300px;
      max-height: 300px; }
      #about .divider-line.animate {
        max-height: 300px; }
    #about h2 {
      font-weight: normal;
      font-family: "Exo 2";
      font-weight: 600;
      font-size: 18px;
      color: rgba(8, 11, 35, 0.8);
      letter-spacing: 0.38px;
      line-height: 24px;
      margin-top: 15px; }
  #services {
    background: #FAFAFA;
    padding-bottom: 60px; }
    #services .col-details {
      width: 100%; }
    #services .service-details-box {
      position: relative;
      height: auto;
      margin: 0 0 45px; }
      #services .service-details-box .divider {
        display: none; }
      #services .service-details-box .service-inner {
        position: relative;
        width: 100%;
        padding: 0 30px 0 30px;
        background: url(../img/service-mobile-bg.png);
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: left center;
        overflow: hidden;
        opacity: 1;
        margin-bottom: 45px; }
        #services .service-details-box .service-inner.active {
          opacity: 1; }
    #services .service-interaction {
      margin-top: 0px; }
    #services .service-intro {
      padding-bottom: 60px; }
  #wheel-outer {
    position: relative;
    margin-top: -60px;
    float: none;
    margin-right: 0px; }
  /*sector*/
  #sectors .sector-item {
    width: 48%; }
  /*process */
  #process {
    overflow: hidden; }
    #process .process-chart .process-wave {
      margin-left: -140px; }
      #process .process-chart .process-wave .step-arrow {
        top: 180px; }
        #process .process-chart .process-wave .step-arrow.left {
          left: 160px; }
        #process .process-chart .process-wave .step-arrow.right {
          right: 20px; }
  /* CONTACT */
  #contact {
    padding-bottom: 90px; }
    #contact .contact-box {
      margin-right: 0px;
      padding-bottom: 16px;
      margin-bottom: 30px; }
  footer {
    text-align: center; }
    footer .ft-contact {
      margin-top: 50px; }
      footer .ft-contact .separator {
        margin: 8px auto; }
    footer .copyright {
      margin-top: 30px; } }

@media only screen and (max-width: 640px) {
  section {
    padding: 60px 0; }
  #lead {
    padding: 180px 0; }
    #lead .contact-block {
      width: 100%; }
      #lead .contact-block .container {
        width: 100%;
        margin: 0;
        padding: 0;
        border-radius: 0; }
  #about {
    padding: 60px 0; }
    #about h2 {
      margin-top: 0px; }
    #about .divider-line {
      margin: 0px 15px; }
  /*sector*/
  #sectors {
    padding: 60px 0; }
    #sectors .sector-item {
      width: 110%;
      margin-left: -15px;
      margin-right: -15px; }
    #sectors .sector-box {
      border-radius: 0px; }
  #process {
    padding: 60px 0; }
    #process .process-chart {
      height: 380px;
      padding-top: 30px;
      max-width: 380px;
      margin: auto; }
      #process .process-chart .process-wave {
        position: relative;
        margin-left: auto;
        height: auto;
        width: 100%; }
        #process .process-chart .process-wave img.wavechart {
          display: none; }
        #process .process-chart .process-wave #drawing {
          width: 100%;
          display: none; }
          #process .process-chart .process-wave #drawing svg {
            width: 100%; }
            #process .process-chart .process-wave #drawing svg .connector.desktop {
              display: none; }
            #process .process-chart .process-wave #drawing svg .connector.mobile {
              display: block; }
        #process .process-chart .process-wave .process-node {
          width: 120px;
          opacity: 0;
          -webkit-transition: all 500ms ease-in-out;
          -moz-transition: all 500ms ease-in-out;
          -o-transition: all 500ms ease-in-out;
          transition: all 500ms ease-in-out; }
          #process .process-chart .process-wave .process-node.active {
            opacity: 1; }
          #process .process-chart .process-wave .process-node#assess, #process .process-chart .process-wave .process-node#act, #process .process-chart .process-wave .process-node#devise, #process .process-chart .process-wave .process-node#collect {
            top: 0;
            left: 50%;
            transform: translateX(-50%); }
          #process .process-chart .process-wave .process-node h4 {
            font-size: 18px; }
          #process .process-chart .process-wave .process-node .indicator {
            display: none; }
        #process .process-chart .process-wave .step-arrow {
          top: 68px; }
          #process .process-chart .process-wave .step-arrow.left {
            left: 20px; }
        #process .process-chart .process-wave .node-inner {
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          text-align: center;
          top: 45px; }
          #process .process-chart .process-wave .node-inner .icon {
            margin: 0px auto 30px; }
          #process .process-chart .process-wave .node-inner .content {
            margin: 0 30px; }
            #process .process-chart .process-wave .node-inner .content h3, #process .process-chart .process-wave .node-inner .content p {
              max-width: 100%; }
  @keyframes glowbarmobile {
    0% {
      opacity: .4; }
    50% {
      opacity: 1; }
    100% {
      background-position: 240px 0;
      opacity: .4; } }
  #advantage {
    padding: 60px 0; }
    #advantage .chart-wrap {
      max-width: 280px;
      margin: 60px auto 0; }
      #advantage .chart-wrap .glow-bar, #advantage .chart-wrap .soft-bar {
        background-repeat: no-repeat;
        background-position: -240px 0px;
        width: 238px; }
        #advantage .chart-wrap .glow-bar.animate, #advantage .chart-wrap .soft-bar.animate {
          -webkit-animation: glowbarmobile 3.5s;
          /* Safari 4+ */
          -moz-animation: glowbarmobile 3.5s;
          /* Fx 5+ */
          -o-animation: glowbarmobile 3.5s;
          /* Opera 12+ */
          animation: glowbarmobile 3.5s;
          /* IE 10+, Fx 29+ */ }
        #advantage .chart-wrap .glow-bar.a, #advantage .chart-wrap .soft-bar.a {
          transform: rotate(63deg);
          top: 200px;
          right: -30px; }
        #advantage .chart-wrap .glow-bar.b, #advantage .chart-wrap .soft-bar.b {
          transform: rotate(-63deg);
          top: 200px;
          left: -30px; }
        #advantage .chart-wrap .glow-bar.c, #advantage .chart-wrap .soft-bar.c {
          width: 200px;
          top: 300px;
          left: 40px;
          transform: rotate(180deg); }
  #advantage [data-tooltip]:before {
    width: 155px; } }

